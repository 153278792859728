var exports = {};
// Generated by Haxe 3.4.4
var hsluv = hsluv || {};

hsluv.Geometry = function () {};

hsluv.Geometry.intersectLineLine = function (a, b) {
  var x = (a.intercept - b.intercept) / (b.slope - a.slope);
  var y = a.slope * x + a.intercept;
  return {
    x: x,
    y: y
  };
};

hsluv.Geometry.distanceFromOrigin = function (point) {
  return Math.sqrt(Math.pow(point.x, 2) + Math.pow(point.y, 2));
};

hsluv.Geometry.distanceLineFromOrigin = function (line) {
  return Math.abs(line.intercept) / Math.sqrt(Math.pow(line.slope, 2) + 1);
};

hsluv.Geometry.perpendicularThroughPoint = function (line, point) {
  var slope = -1 / line.slope;
  var intercept = point.y - slope * point.x;
  return {
    slope: slope,
    intercept: intercept
  };
};

hsluv.Geometry.angleFromOrigin = function (point) {
  return Math.atan2(point.y, point.x);
};

hsluv.Geometry.normalizeAngle = function (angle) {
  var m = 2 * Math.PI;
  return (angle % m + m) % m;
};

hsluv.Geometry.lengthOfRayUntilIntersect = function (theta, line) {
  return line.intercept / (Math.sin(theta) - line.slope * Math.cos(theta));
};

hsluv.Hsluv = function () {};

hsluv.Hsluv.getBounds = function (L) {
  var result = [];
  var sub1 = Math.pow(L + 16, 3) / 1560896;
  var sub2 = sub1 > hsluv.Hsluv.epsilon ? sub1 : L / hsluv.Hsluv.kappa;
  var _g = 0;

  while (_g < 3) {
    var c = _g++;
    var m1 = hsluv.Hsluv.m[c][0];
    var m2 = hsluv.Hsluv.m[c][1];
    var m3 = hsluv.Hsluv.m[c][2];
    var _g1 = 0;

    while (_g1 < 2) {
      var t = _g1++;
      var top1 = (284517 * m1 - 94839 * m3) * sub2;
      var top2 = (838422 * m3 + 769860 * m2 + 731718 * m1) * L * sub2 - 769860 * t * L;
      var bottom = (632260 * m3 - 126452 * m2) * sub2 + 126452 * t;
      result.push({
        slope: top1 / bottom,
        intercept: top2 / bottom
      });
    }
  }

  return result;
};

hsluv.Hsluv.maxSafeChromaForL = function (L) {
  var bounds = hsluv.Hsluv.getBounds(L);
  var min = Infinity;
  var _g = 0;

  while (_g < bounds.length) {
    var bound = bounds[_g];
    ++_g;
    var length = hsluv.Geometry.distanceLineFromOrigin(bound);
    min = Math.min(min, length);
  }

  return min;
};

hsluv.Hsluv.maxChromaForLH = function (L, H) {
  var hrad = H / 360 * Math.PI * 2;
  var bounds = hsluv.Hsluv.getBounds(L);
  var min = Infinity;
  var _g = 0;

  while (_g < bounds.length) {
    var bound = bounds[_g];
    ++_g;
    var length = hsluv.Geometry.lengthOfRayUntilIntersect(hrad, bound);

    if (length >= 0) {
      min = Math.min(min, length);
    }
  }

  return min;
};

hsluv.Hsluv.dotProduct = function (a, b) {
  var sum = 0;
  var _g1 = 0;
  var _g = a.length;

  while (_g1 < _g) {
    var i = _g1++;
    sum += a[i] * b[i];
  }

  return sum;
};

hsluv.Hsluv.fromLinear = function (c) {
  if (c <= 0.0031308) {
    return 12.92 * c;
  } else {
    return 1.055 * Math.pow(c, 0.4166666666666667) - 0.055;
  }
};

hsluv.Hsluv.toLinear = function (c) {
  if (c > 0.04045) {
    return Math.pow((c + 0.055) / 1.055, 2.4);
  } else {
    return c / 12.92;
  }
};

hsluv.Hsluv.xyzToRgb = function (tuple) {
  return [hsluv.Hsluv.fromLinear(hsluv.Hsluv.dotProduct(hsluv.Hsluv.m[0], tuple)), hsluv.Hsluv.fromLinear(hsluv.Hsluv.dotProduct(hsluv.Hsluv.m[1], tuple)), hsluv.Hsluv.fromLinear(hsluv.Hsluv.dotProduct(hsluv.Hsluv.m[2], tuple))];
};

hsluv.Hsluv.rgbToXyz = function (tuple) {
  var rgbl = [hsluv.Hsluv.toLinear(tuple[0]), hsluv.Hsluv.toLinear(tuple[1]), hsluv.Hsluv.toLinear(tuple[2])];
  return [hsluv.Hsluv.dotProduct(hsluv.Hsluv.minv[0], rgbl), hsluv.Hsluv.dotProduct(hsluv.Hsluv.minv[1], rgbl), hsluv.Hsluv.dotProduct(hsluv.Hsluv.minv[2], rgbl)];
};

hsluv.Hsluv.yToL = function (Y) {
  if (Y <= hsluv.Hsluv.epsilon) {
    return Y / hsluv.Hsluv.refY * hsluv.Hsluv.kappa;
  } else {
    return 116 * Math.pow(Y / hsluv.Hsluv.refY, 0.3333333333333333) - 16;
  }
};

hsluv.Hsluv.lToY = function (L) {
  if (L <= 8) {
    return hsluv.Hsluv.refY * L / hsluv.Hsluv.kappa;
  } else {
    return hsluv.Hsluv.refY * Math.pow((L + 16) / 116, 3);
  }
};

hsluv.Hsluv.xyzToLuv = function (tuple) {
  var X = tuple[0];
  var Y = tuple[1];
  var Z = tuple[2];
  var divider = X + 15 * Y + 3 * Z;
  var varU = 4 * X;
  var varV = 9 * Y;

  if (divider != 0) {
    varU /= divider;
    varV /= divider;
  } else {
    varU = NaN;
    varV = NaN;
  }

  var L = hsluv.Hsluv.yToL(Y);

  if (L == 0) {
    return [0, 0, 0];
  }

  var U = 13 * L * (varU - hsluv.Hsluv.refU);
  var V = 13 * L * (varV - hsluv.Hsluv.refV);
  return [L, U, V];
};

hsluv.Hsluv.luvToXyz = function (tuple) {
  var L = tuple[0];
  var U = tuple[1];
  var V = tuple[2];

  if (L == 0) {
    return [0, 0, 0];
  }

  var varU = U / (13 * L) + hsluv.Hsluv.refU;
  var varV = V / (13 * L) + hsluv.Hsluv.refV;
  var Y = hsluv.Hsluv.lToY(L);
  var X = 0 - 9 * Y * varU / ((varU - 4) * varV - varU * varV);
  var Z = (9 * Y - 15 * varV * Y - varV * X) / (3 * varV);
  return [X, Y, Z];
};

hsluv.Hsluv.luvToLch = function (tuple) {
  var L = tuple[0];
  var U = tuple[1];
  var V = tuple[2];
  var C = Math.sqrt(U * U + V * V);
  var H;

  if (C < 1e-8) {
    H = 0;
  } else {
    var Hrad = Math.atan2(V, U);
    H = Hrad * 180 / Math.PI;

    if (H < 0) {
      H = 360 + H;
    }
  }

  return [L, C, H];
};

hsluv.Hsluv.lchToLuv = function (tuple) {
  var L = tuple[0];
  var C = tuple[1];
  var H = tuple[2];
  var Hrad = H / 360 * 2 * Math.PI;
  var U = Math.cos(Hrad) * C;
  var V = Math.sin(Hrad) * C;
  return [L, U, V];
};

hsluv.Hsluv.hsluvToLch = function (tuple) {
  var H = tuple[0];
  var S = tuple[1];
  var L = tuple[2];

  if (L > 99.9999999) {
    return [100, 0, H];
  }

  if (L < 1e-8) {
    return [0, 0, H];
  }

  var max = hsluv.Hsluv.maxChromaForLH(L, H);
  var C = max / 100 * S;
  return [L, C, H];
};

hsluv.Hsluv.lchToHsluv = function (tuple) {
  var L = tuple[0];
  var C = tuple[1];
  var H = tuple[2];

  if (L > 99.9999999) {
    return [H, 0, 100];
  }

  if (L < 1e-8) {
    return [H, 0, 0];
  }

  var max = hsluv.Hsluv.maxChromaForLH(L, H);
  var S = C / max * 100;
  return [H, S, L];
};

hsluv.Hsluv.hpluvToLch = function (tuple) {
  var H = tuple[0];
  var S = tuple[1];
  var L = tuple[2];

  if (L > 99.9999999) {
    return [100, 0, H];
  }

  if (L < 1e-8) {
    return [0, 0, H];
  }

  var max = hsluv.Hsluv.maxSafeChromaForL(L);
  var C = max / 100 * S;
  return [L, C, H];
};

hsluv.Hsluv.lchToHpluv = function (tuple) {
  var L = tuple[0];
  var C = tuple[1];
  var H = tuple[2];

  if (L > 99.9999999) {
    return [H, 0, 100];
  }

  if (L < 1e-8) {
    return [H, 0, 0];
  }

  var max = hsluv.Hsluv.maxSafeChromaForL(L);
  var S = C / max * 100;
  return [H, S, L];
};

hsluv.Hsluv.rgbToHex = function (tuple) {
  var h = "#";
  var _g = 0;

  while (_g < 3) {
    var i = _g++;
    var chan = tuple[i];
    var c = Math.round(chan * 255);
    var digit2 = c % 16;
    var digit1 = (c - digit2) / 16 | 0;
    h += hsluv.Hsluv.hexChars.charAt(digit1) + hsluv.Hsluv.hexChars.charAt(digit2);
  }

  return h;
};

hsluv.Hsluv.hexToRgb = function (hex) {
  hex = hex.toLowerCase();
  var ret = [];
  var _g = 0;

  while (_g < 3) {
    var i = _g++;
    var digit1 = hsluv.Hsluv.hexChars.indexOf(hex.charAt(i * 2 + 1));
    var digit2 = hsluv.Hsluv.hexChars.indexOf(hex.charAt(i * 2 + 2));
    var n = digit1 * 16 + digit2;
    ret.push(n / 255);
  }

  return ret;
};

hsluv.Hsluv.lchToRgb = function (tuple) {
  return hsluv.Hsluv.xyzToRgb(hsluv.Hsluv.luvToXyz(hsluv.Hsluv.lchToLuv(tuple)));
};

hsluv.Hsluv.rgbToLch = function (tuple) {
  return hsluv.Hsluv.luvToLch(hsluv.Hsluv.xyzToLuv(hsluv.Hsluv.rgbToXyz(tuple)));
};

hsluv.Hsluv.hsluvToRgb = function (tuple) {
  return hsluv.Hsluv.lchToRgb(hsluv.Hsluv.hsluvToLch(tuple));
};

hsluv.Hsluv.rgbToHsluv = function (tuple) {
  return hsluv.Hsluv.lchToHsluv(hsluv.Hsluv.rgbToLch(tuple));
};

hsluv.Hsluv.hpluvToRgb = function (tuple) {
  return hsluv.Hsluv.lchToRgb(hsluv.Hsluv.hpluvToLch(tuple));
};

hsluv.Hsluv.rgbToHpluv = function (tuple) {
  return hsluv.Hsluv.lchToHpluv(hsluv.Hsluv.rgbToLch(tuple));
};

hsluv.Hsluv.hsluvToHex = function (tuple) {
  return hsluv.Hsluv.rgbToHex(hsluv.Hsluv.hsluvToRgb(tuple));
};

hsluv.Hsluv.hpluvToHex = function (tuple) {
  return hsluv.Hsluv.rgbToHex(hsluv.Hsluv.hpluvToRgb(tuple));
};

hsluv.Hsluv.hexToHsluv = function (s) {
  return hsluv.Hsluv.rgbToHsluv(hsluv.Hsluv.hexToRgb(s));
};

hsluv.Hsluv.hexToHpluv = function (s) {
  return hsluv.Hsluv.rgbToHpluv(hsluv.Hsluv.hexToRgb(s));
};

hsluv.Hsluv.m = [[3.240969941904521, -1.537383177570093, -0.498610760293], [-0.96924363628087, 1.87596750150772, 0.041555057407175], [0.055630079696993, -0.20397695888897, 1.056971514242878]];
hsluv.Hsluv.minv = [[0.41239079926595, 0.35758433938387, 0.18048078840183], [0.21263900587151, 0.71516867876775, 0.072192315360733], [0.019330818715591, 0.11919477979462, 0.95053215224966]];
hsluv.Hsluv.refY = 1;
hsluv.Hsluv.refU = 0.19783000664283;
hsluv.Hsluv.refV = 0.46831999493879;
hsluv.Hsluv.kappa = 903.2962962;
hsluv.Hsluv.epsilon = 0.0088564516;
hsluv.Hsluv.hexChars = "0123456789abcdef";
var root = {
  "hsluvToRgb": hsluv.Hsluv.hsluvToRgb,
  "rgbToHsluv": hsluv.Hsluv.rgbToHsluv,
  "hpluvToRgb": hsluv.Hsluv.hpluvToRgb,
  "rgbToHpluv": hsluv.Hsluv.rgbToHpluv,
  "hsluvToHex": hsluv.Hsluv.hsluvToHex,
  "hexToHsluv": hsluv.Hsluv.hexToHsluv,
  "hpluvToHex": hsluv.Hsluv.hpluvToHex,
  "hexToHpluv": hsluv.Hsluv.hexToHpluv,
  "lchToHpluv": hsluv.Hsluv.lchToHpluv,
  "hpluvToLch": hsluv.Hsluv.hpluvToLch,
  "lchToHsluv": hsluv.Hsluv.lchToHsluv,
  "hsluvToLch": hsluv.Hsluv.hsluvToLch,
  "lchToLuv": hsluv.Hsluv.lchToLuv,
  "luvToLch": hsluv.Hsluv.luvToLch,
  "xyzToLuv": hsluv.Hsluv.xyzToLuv,
  "luvToXyz": hsluv.Hsluv.luvToXyz,
  "xyzToRgb": hsluv.Hsluv.xyzToRgb,
  "rgbToXyz": hsluv.Hsluv.rgbToXyz,
  "lchToRgb": hsluv.Hsluv.lchToRgb,
  "rgbToLch": hsluv.Hsluv.rgbToLch
};
exports = root;
export default exports;